import React, { useState, useEffect } from "react";
import Navbar from "./components/Navbar";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Banner from "./components/banner";
import "./fonts/stylesheet.css";
import "./style.css";
import "./Responsive.css";
import "bootstrap/dist/css/bootstrap.min.css";
import IndividualIntervalsExample from "./components/slider";
import Slider2 from "./components/Slider2";
import Footer from "./components/Footer";

function App() {
  const [load, upadateLoad] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      upadateLoad(false);
    }, 1200);

    return () => clearTimeout(timer);
  }, []);

  return (
    <Router>
      <div className="App" id={load ? "no-scroll" : "scroll"}>
        <Navbar />
        <div>
          <Banner />
        <IndividualIntervalsExample />
        <Slider2 />
        <Footer />
        
        </div>
      </div>
    </Router>
  );
}

export default App;
