import Carousel from "react-bootstrap/Carousel";
import Banner from "./banner";
import slider_one from "../Assets/images/s-1.png";
import slider_two from "../Assets/images/s-2.png";
import slider_three from "../Assets/images/s-3.png";
import slider_four from "../Assets/images/s-4.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function IndividualIntervalsExample() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    nav: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: true,
    responsive: [
      {
        breakpoint: 1279,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          arrows: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
          dots: false,
          arrows: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
          arrows: true,
        },
      },
    ],
  };
  return (
    <>
      <div className="slider-sec">
        <div className="container">
            <div className="upload_coument_right">
                <button className="blue_button">Help</button>
                <h2 className="h2">How It’s Work</h2>
            </div>
          <h3 className="h3">Create Credit Coupon</h3>
          <Slider {...settings} className="custom-slider">
            <div className="custom-item">
              <div className="slider-block">
                  <img src={slider_one} alt="upload_document" />
                  <h4 className="h4">Step 1</h4>
                  <p>Open the Print & Go mobile app and log in.</p>
                </div>
            </div>
            <div className="custom-item">
              <div className="slider-block">
                  <img src={slider_two} alt="upload_document" />
                  <h4 className="h4">Step 2</h4>
                  <p>Go to the "Credits" section.</p>
                </div>
            </div>
            <div className="custom-item">
              <div className="slider-block">
                  <img src={slider_three} alt="upload_document" />
                  <h4 className="h4">Step 3</h4>
                  <p>Choose from a variety of credit coupon options (e.g., $10, $20, $50).</p>
                </div>
            </div>
            <div className="custom-item">
              <div className="slider-block">
                  <img src={slider_four} alt="upload_document" />
                  <h4 className="h4">Step 4</h4>
                  <p>Securely pay using your preferred payment method (e.g., credit card, mobile wallet).</p>
                </div>
            </div>
            <div className="custom-item">
              <div className="slider-block">
                  <img src={slider_one} alt="upload_document" />
                  <h4 className="h4">Step 5</h4>
                  <p>Open the Print & Go mobile app and log in.</p>
                </div>
            </div>
            <div className="custom-item">
              <div className="slider-block">
                  <img src={slider_two} alt="upload_document" />
                  <h4 className="h4">Step 6</h4>
                  <p>Open the Print & Go mobile app and log in.</p>
                </div>
            </div>
          </Slider>
        </div>
      </div>
    </>
  );
}

export default IndividualIntervalsExample;
