import React from "react";
import Carousel from "react-bootstrap/Carousel";
import Banner from "./banner";
import slider_one from "../Assets/images/s-1.png";
import slider2_two from "../Assets/images/S2-2.png";
import slider2_three from "../Assets/images/S2-3.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function Slider2() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    nav: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: true,
    responsive: [
      {
        breakpoint: 1279,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          arrows: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
          dots: false,
          arrows: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
          arrows: true,
        },
      },
    ],
  };
  return (
    <>
      <div className="slider-sec slider-top">
        <div className="container">
          <h3 className="h3">Upload Document</h3>
          <Slider {...settings} className="custom-slider">
            <div className="custom-item">
              <div className="slider-block">
                <img src={slider_one} alt="upload_document" />
                <h4 className="h4">Step 1</h4>
                <p>Open the Print & Go mobile app and log in.</p>
              </div>
            </div>
            <div className="custom-item">
              <div className="slider-block">
                <img src={slider2_two} alt="upload_document" />
                <h4 className="h4">Step 2</h4>
                <p>Go to the "Credits" section.</p>
              </div>
            </div>
            <div className="custom-item">
              <div className="slider-block">
                <img src={slider2_three} alt="upload_document" />
                <h4 className="h4">Step 3</h4>
                <p>
                  Choose from a variety of credit coupon options (e.g., $10,
                  $20, $50).
                </p>
              </div>
            </div>
            <div className="custom-item">
              <div className="slider-block">
                <img src={slider_one} alt="upload_document" />
                <h4 className="h4">Step 1</h4>
                <p>Open the Print & Go mobile app and log in.</p>
              </div>
            </div>
            <div className="custom-item">
              <div className="slider-block">
                <img src={slider2_two} alt="upload_document" />
                <h4 className="h4">Step 2</h4>
                <p>Go to the "Credits" section.</p>
              </div>
            </div>
            <div className="custom-item">
              <div className="slider-block">
                <img src={slider2_three} alt="upload_document" />
                <h4 className="h4">Step 3</h4>
                <p>
                  Choose from a variety of credit coupon options (e.g., $10,
                  $20, $50).
                </p>
              </div>
            </div>
            
          </Slider>
        </div>
      </div>
    </>
  );
}

export default Slider2;
